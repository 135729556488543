import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import cn from 'classnames';
import { useAppDispatch } from '../../../store/hooks';
import { addCategoryDataElem, addCategoryDataEndpoint } from '../../../store/sesSlice';
import { addCategoryDataRobot } from '../../../store/sesRobotSlice';
import useTranslate from '../../../hooks/useTranslate';
import { colorPrimary } from '../../../constants/colors';
import { CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST, ConditionTypeExtended } from '../../../constants/robotConfigLists';
import { RobotActionEventType } from '../../../types/sesRobotTypes';
import { ConditionType, DataElemActionEventType, IConditionData, IConditionVariable } from '../../../types/sesTypes';
import { IFormAddingCategoryDataProps } from './FormAddingCategoryData.props';
import styles from './FormAddingCategoryData.module.scss';

const FormAddingCategoryData = ({ actionEvent, itsAction, categoryId, categoryBlockIdx, channel, channelIdx, changeFlg, setChangeFlg }: IFormAddingCategoryDataProps): JSX.Element => {
	const [conditionType, setConditionType] = useState<ConditionTypeExtended>('chooseType'); // тип условия присвоениея категории

	const dispatch = useAppDispatch();

	const translate = useTranslate(); // hook для перевода текста

	// обработчик добавления данных категории
	const addCategoryDataHandler = (e: SelectChangeEvent<string>): void => {
		const data: (IConditionData | IConditionVariable) = e.target.value === 'data' ?
			{
				type: 'data',
				depth: 5,
				id: '',
				operation: 'exists',
				value: '',
			} : {
				type: 'variable',
				id: '',
				operation: 'exists',
				value: '',
			};

		setConditionType(e.target.value as Extract<ConditionType, 'data' | 'variable'>);
		itsAction === 'robot' && dispatch(addCategoryDataRobot({
			actionEvent: actionEvent as RobotActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data,
		}));
		itsAction === 'dataElement' && dispatch(addCategoryDataElem({
			actionEvent: actionEvent as DataElemActionEventType,
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data,
		}));
		itsAction === 'endpoint' && dispatch(addCategoryDataEndpoint({
			channel,
			channelIdx,
			categoryId,
			categoryBlockIdx,
			data,
		}));
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		setConditionType('chooseType'); // сбрасываем поле
	};

	return (
		<div className={cn(styles.container, {
			[styles.containerOpacity]: conditionType === 'chooseType'
		})}>
			{/* тип условия запуска действия */}
			<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
				<InputLabel sx={{ fontSize: 13 }}>{translate('select_type')}</InputLabel>
				<Select
					required
					label={translate('select_type')}
					value={conditionType}
					onChange={addCategoryDataHandler}
					style={{ fontSize: 13, height: 33, color: colorPrimary }}
				>
					{CONDITION_RUN_ACTION_TYPE_EXTENDED_LIST.filter(type => type.type !== 'interval').map(({ type, translation }) =>
						<MenuItem key={type} value={type} sx={{ fontSize: 13, color: colorPrimary }} disabled={type === 'chooseType'}>
							{translate(translation)}
						</MenuItem>
					)}
				</Select>
			</FormControl>
		</div>
	);
};

export default FormAddingCategoryData;
