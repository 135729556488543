import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';
import { RootState } from './store';
import axiosApi from '../helpers/axios';
import { ENDPOINTS_SES, URL_SES } from '../constants/api_endpoints';
import { Action, ActionType, ActionVariantType, ConditionType, ConditionVariantType, DataElemActionEventType, DataElemRType, EndpointType, IActionInternal, IActionPhrase, IAddDataElemProps, IAddEndpointProps, IAddingDataElemAction, IAddingDataElemResponse, IAddingEndpointResponse, IConditionData, IConditionVariable, IDataElemItem, IDebuggerAnswerResponse, IDebuggerSessionResponse, IDeleteDataElemProps, IDeleteEndpointProps, IDialog, IEditDataElemProps, IEditEndpointProps, IElementResponse, IEndpointElemItem, IEndpointResponse, IGetDataElemProps, IGetDebuggerAnswerProps, IGetDebuggerSessionProps, IGetEndpointProps, IResponse, ISesState, ServiceType } from '../types/sesTypes';
import { getPostfixRobotId } from './sesRobotSlice';
import { CachingType } from '../types/qasTypes';
import { DataElemType } from '../types/cloudTypes';
import { RequestStatus, ResponseStatus } from '../types/statusTypes';

const initialState: ISesState = {
  dataList: {
    data: [],
    dictionary: {},
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  dataElem: {
    element: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingData: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  editingData: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingData: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },

  endpointList: {
    data: [],
    dictionary: {},
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  endpointElem: {
    endpoint: null,
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  addingEndpoint: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  editingEndpoint: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },
  deletingEndpoint: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
  },

  debuggerAnswer: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    answer: null,
    dialog: [],
    session: '',
    endOfSession: false,
    question: '',
  },
  debuggerSession: {
    status: RequestStatus.IDLE,
    error: ResponseStatus.SUCCESS,
    message: '',
    sessionData: null,
  },
};

// список элементов данных
export const getDataList = createAsyncThunk(
  'ses/getDataList',
  async (robotId: string, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IDataElemItem[] | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.DATA_LIST}/${getPostfixRobotId(getState() as RootState, robotId)}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// элемент данных
export const getDataElem = createAsyncThunk(
  'ses/getDataElem',
  async ({ robotId, elementId }: IGetDataElemProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IElementResponse | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.DATA_GET}/${getPostfixRobotId(getState() as RootState, robotId)}/${elementId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление элемента данных
export const addDataElem = createAsyncThunk(
  'ses/addDataElem',
  async ({ robotId, data }: IAddDataElemProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAddingDataElemResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.DATA_ADD}/${robotId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение элемента данных
export const editDataElem = createAsyncThunk(
  'ses/editDataElem',
  async ({ robotId, dataId, data }: IEditDataElemProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.DATA_EDIT}/${robotId}/${dataId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление элемента данных
export const deleteDataElem = createAsyncThunk(
  'ses/deleteDataElem',
  async ({ robotId, dataId }: IDeleteDataElemProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_SES}/${ENDPOINTS_SES.DATA_DELETE}/${robotId}/${dataId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// список конечных точек
export const getEndpointList = createAsyncThunk(
  'ses/getEndpoints',
  async (robotId: string, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IEndpointElemItem[] | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ENDPOINT_LIST}/${getPostfixRobotId(getState() as RootState, robotId)}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// конечная точка
export const getEndpoint = createAsyncThunk(
  'ses/getEndpoint',
  async ({ robotId, endpointId }: IGetEndpointProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IEndpointResponse | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.ENDPOINT_GET}/${getPostfixRobotId(getState() as RootState, robotId)}/${endpointId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// добавление конечной точки
export const addEndpoint = createAsyncThunk(
  'ses/addEndpoint',
  async ({ robotId, data }: IAddEndpointProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IAddingEndpointResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ENDPOINT_ADD}/${robotId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// изменение конечной точки
export const editEndpoint = createAsyncThunk(
  'ses/editEndpoint',
  async ({ robotId, endpointId, data }: IEditEndpointProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ENDPOINT_EDIT}/${robotId}/${endpointId}`, {
        data: JSON.stringify(data),
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// удаление конечной точки
export const deleteEndpoint = createAsyncThunk(
  'ses/deleteEndpoint',
  async ({ robotId, endpointId }: IDeleteEndpointProps, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<IResponse> = await axiosApi.delete(`${URL_SES}/${ENDPOINTS_SES.ENDPOINT_DELETE}/${robotId}/${endpointId}`);
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// ответ робота в отладчике
export const getDebuggerAnswer = createAsyncThunk(
  'ses/getDebuggerAnswer',
  async ({ robotId, sessionId, channelId, text, formData, audioAnswers, variables }: IGetDebuggerAnswerProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IDebuggerAnswerResponse | IResponse> = await axiosApi.post(`${URL_SES}/${ENDPOINTS_SES.ASK}/${getPostfixRobotId(getState() as RootState, robotId)}`, {
        session: sessionId,
        channel: channelId,
        text,
        audio: formData?.get('file'),
        tts: audioAnswers,
        variables: JSON.stringify(variables),
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

// данные сессии отладчика
export const getDebuggerSession = createAsyncThunk(
  'ses/getDebuggerSession',
  async ({ robotId, sessionId }: IGetDebuggerSessionProps, { getState, rejectWithValue }) => {
    try {
      const response: AxiosResponse<IDebuggerSessionResponse | IResponse> = await axiosApi.get(`${URL_SES}/${ENDPOINTS_SES.SESSION}/${getPostfixRobotId(getState() as RootState, robotId)}/${sessionId}`);
      const dictionaryCategories = (getState() as RootState).qas.categoriesList.dictionary;
      return {
        responseData: response.data, // response
        dictionaryCategories, // проброс словаря категорий
      };
    } catch (error) {
      if (error) {
        // возвращаем данные ошибки, пришедшие с бэка
        return rejectWithValue(error);
      }
    }
  }
);

const sesSlice = createSlice({
  name: 'ses',
  initialState,
  reducers: {
    // изменение имени элемента данных
    editDataElemName: (state, action: PayloadAction<{ dataElemId: string, dataElemName: string }>) => {
      state.dataList.data = state.dataList.data.map(dataElem => {
        if (dataElem.id === action.payload.dataElemId) return { ...dataElem, name: action.payload.dataElemName };
        else return dataElem;
      });
    },
    // изменение подтипа элемента данных
    editDataElemRType: (state, action: PayloadAction<{ dataElemId: string, dataElemRType: DataElemRType }>) => {
      state.dataList.data = state.dataList.data.map(dataElem => {
        if (dataElem.id === action.payload.dataElemId) return { ...dataElem, rtype: action.payload.dataElemRType };
        else return dataElem;
      });
    },
    // добавление данных формы добавления элемента
    addingAddElementFormData: (state, action: PayloadAction<{ name: string, type: DataElemType }>) => {
      state.dataElem.element = {
        id: '',
        name: action.payload.name,
        type: action.payload.type,
        model: '',
        param: '',
        actions: {},
        forEndpointRunOnly: false,
        slaveData: [],
        weight: 3,
        rtype: 'report',
      };
    },
    // добавление требуемых данных (slaveData)
    addSlaveData: (state, action: PayloadAction<string>) => {
      if (state.dataElem.element) state.dataElem.element.slaveData.push(action.payload);
    },
    // изменение требуемых данных (slaveData)
    editSlaveData: (state, action: PayloadAction<{ slaveData: string, idx: number }>) => {
      if (state.dataElem.element) {
        state.dataElem.element.slaveData.splice(action.payload.idx, 1, action.payload.slaveData);
      }
    },
    // удаление требуемых данных (slaveData)
    deleteSlaveData: (state, action: PayloadAction<number>) => {
      if (state.dataElem.element) {
        state.dataElem.element.slaveData.splice(action.payload, 1);
      }
    },
    // изменение флага использования элемента данных только для конечной точки
    changeForEndpointRunOnly: (state, action: PayloadAction<boolean>) => {
      if (state.dataElem.element) {
        state.dataElem.element.forEndpointRunOnly = action.payload;
      }
    },
    // добавление действия элемента данных
    addingDataElemAction: (state, action: PayloadAction<IAddingDataElemAction>) => {
      if (state.dataElem.element) {
        // если есть событие действия
        if (action.payload.actionEvent in state.dataElem.element.actions) {
          // и если есть канал
          if (action.payload.channel in state.dataElem.element.actions[action.payload.actionEvent]) {
            state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel].push(action.payload.action); // добавляем действие
          }
          // если нет канала - создаем
          else {
            state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel] = [action.payload.action];
          }
        }
        // если нет события действия - создаем событие и канал
        else {
          state.dataElem.element.actions[action.payload.actionEvent] = {
            [action.payload.channel]: [action.payload.action],
          };
        }
      }
    },
    // изменение действия элемента данных
    changeActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number, action: Action }>) => {
      if (state.dataElem.element) {
        if (action.payload.action === 'say') {
          state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
            action: 'say',
            type: 'linear',
            text: [''],
            conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
          };
        } else state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
          action: 'end',
          conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
        };
      }
    },
    // удаление действия элемента данных
    deleteActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number }>) => {
      state.dataElem.element?.actions[action.payload.actionEvent][action.payload.channel].splice(action.payload.idx, 1);
    },
    // изменение типа действия элемента данных
    changeActionTypeDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number, action: Action, actionType: ActionType, text?: string[] }>) => {
      if (state.dataElem.element) {
        if (action.payload.action === 'say') {
          switch (action.payload.actionType) {
            case 'linear':
            case 'random':
              state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                text: action.payload.text || [''],
                conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
              };
              break;
            case 'external':
              state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                script: '',
                conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
              };
              break;
            case 'internal':
              state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                service: 'qas',
                categories: (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] as IActionInternal).categories || {},
                useCache: 'checked',
                useGPT: 'no',
                creative: 'no',
                addAlias: 'no',
                conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
              };
              break;
            default:
              break;
          }
        } else state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
          action: action.payload.action,
          conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
        };
      }
    },
    // добавление фразы в действие элемента данных (по факту - перезапись с добавлением)
    addPhraseInActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number, actionObj: IActionPhrase }>) => {
      if (state.dataElem.element) {
        state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = action.payload.actionObj;
      }
    },
    // изменение фразы в действии элемента данных
    changePhraseInActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idxAction: number, idxPhrase: number, text: string }>) => {
      if (state.dataElem.element) {
        (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idxAction] as IActionPhrase).text[action.payload.idxPhrase] = action.payload.text;
      }
    },
    // удаление фразы в действии элемента данных
    deletePhraseInActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idxAction: number, idxPhrase: number }>) => {
      if (state.dataElem.element) {
        (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idxAction] as IActionPhrase).text.splice(action.payload.idxPhrase, 1);
      }
    },
    // изменение скрипта элемента данных
    changeScriptDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number, script: string }>) => {
      if (state.dataElem.element) {
        state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
          action: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'external',
          script: action.payload.script,
          conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
        };
      }
    },
    // изменение данных сервиса действия элемента данных
    changeServiceDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, idx: number, service: ServiceType, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' }>) => {
      if (state.dataElem.element) {
        state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] = {
          action: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'internal',
          service: action.payload.service,
          categories: (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx] as IActionInternal).categories,
          useCache: action.payload.useCache,
          useGPT: action.payload.useGPT,
          creative: action.payload.creative,
          addAlias: action.payload.addAlias,
          conditions: state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.idx].conditions,
        };
      }
    },
    // добавление данных категории элемента данных
    addCategoryDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.dataElem.element) {
        const actionInternal = state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternal;
        if ('categories' in actionInternal) {
          if (action.payload.categoryId in actionInternal.categories) {
            if (actionInternal.categories[action.payload.categoryId].length > action.payload.categoryBlockIdx) {
              actionInternal.categories[action.payload.categoryId][action.payload.categoryBlockIdx].push(action.payload.data);
            } else {
              actionInternal.categories[action.payload.categoryId].push([action.payload.data]);
            }
          } else {
            actionInternal.categories[action.payload.categoryId] = [[action.payload.data]];
          }
        } else {
          (actionInternal as IActionInternal).categories = {
            [action.payload.categoryId]: [[action.payload.data]]
          };
        }
      }
    },
    // изменение типа данных категории элемента данных
    changeTypeCategoryDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.dataElem.element) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId][action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение данных категории элемента данных
    changeCategoryDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, data: IConditionData }>) => {
      if (state.dataElem.element) {
        (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId][action.payload.categoryBlockIdx][action.payload.categoryIdx] = action.payload.data;
      }
    },
    // удаление данных категории элемента данных
    deleteCategoryDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number }>) => {
      if (state.dataElem.element) {
        const actionInternalCategoryId = (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId];
        if (actionInternalCategoryId[action.payload.categoryBlockIdx].length > 1) {
          actionInternalCategoryId[action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1);
        } else {
          if (actionInternalCategoryId.length > 1) {
            actionInternalCategoryId.splice(action.payload.categoryBlockIdx, 1);
          } else {
            delete (state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId];
          }
        }
      }
    },
    // -------------------------------------------------------------------------
    // добавление условия запуска действия элемента данных
    addConditionRunActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, data: ConditionVariantType }>) => {
      if (state.dataElem.element) {
        const conditions = state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx].conditions; // условия запуска действия
        if (Array.isArray(conditions)) {
          if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
          else conditions.push([action.payload.data]);
        } else {
          state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx] = {
            ...state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx],
            conditions: [[action.payload.data]],
          };
        }
      }
    },
    // изменение типа условия запуска действия элемента данных
    changeTypeConditionRunActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, conditionType: ConditionType }>) => {
      if (state.dataElem.element) {
        const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'interval':
              return {
                type,
                id: '',
              };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx].conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия запуска действия элемента данных
    changeValuesConditionRunActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, data: ConditionVariantType }>) => {
      if (state.dataElem.element) {
        state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx].conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия запуска действия элемента данных
    deleteConditionRunActionDataElem: (state, action: PayloadAction<{ actionEvent: DataElemActionEventType, channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number }>) => {
      if (state.dataElem.element) {
        const conditions = state.dataElem.element.actions[action.payload.actionEvent][action.payload.channel][action.payload.channelIdx].conditions; // условия
        if (conditions && conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions?.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // -------------------------------------------------------------------------
    // изменение имени КТ в списке КТ
    editEndpointName: (state, action: PayloadAction<{ endpointId: string, endpointName: string }>) => {
      state.endpointList.data = state.endpointList.data.map(endpoint => {
        if (endpoint.id === action.payload.endpointId) return { ...endpoint, name: action.payload.endpointName };
        else return endpoint;
      });
    },
    // изменение типа КТ в списке КТ
    editEndpointType: (state, action: PayloadAction<{ endpointId: string, endpointType: EndpointType }>) => {
      state.endpointList.data = state.endpointList.data.map(endpoint => {
        if (endpoint.id === action.payload.endpointId) return { ...endpoint, type: action.payload.endpointType };
        else return endpoint;
      });
    },
    // изменение условий входа КТ в списке КТ
    editEndpointEntry: (state, action: PayloadAction<{ endpointId: string, conditionEntry: (IConditionData | IConditionVariable)[][] }>) => {
      state.endpointList.data = state.endpointList.data.map(endpoint => {
        if (endpoint.id === action.payload.endpointId) return { ...endpoint, entry: action.payload.conditionEntry };
        else return endpoint;
      });
    },
    // добавление данных формы добавления конечной точки
    addingAddEndpointFormData: (state, action: PayloadAction<string>) => {
      state.endpointElem.endpoint = {
        id: '',
        name: action.payload,
        entry: [],
        run: [],
        actions: {},
        type: 'report',
      };
    },
    // добавление действия конечной точке
    addingEndpointAction: (state, action: PayloadAction<{ channel: string, action: ActionVariantType }>) => {
      if (state.endpointElem.endpoint) {
        // если есть канал
        if (action.payload.channel in state.endpointElem.endpoint.actions) {
          state.endpointElem.endpoint.actions[action.payload.channel].push(action.payload.action); // добавляем действие
        }
        // если нет канала - создаем
        else {
          state.endpointElem.endpoint.actions[action.payload.channel] = [action.payload.action];
        }
      }
    },
    // удаление действия конечной точки
    deleteActionEndpoint: (state, action: PayloadAction<{ channel: string, idx: number }>) => {
      state.endpointElem.endpoint?.actions[action.payload.channel].splice(action.payload.idx, 1);
    },
    // изменение действия конечной точки
    changeActionEndpoint: (state, action: PayloadAction<{ channel: string, idx: number, action: Action }>) => {
      if (state.endpointElem.endpoint) {
        if (action.payload.action === 'say') {
          state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
            action: 'say',
            type: 'linear',
            text: [''],
            conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
            setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
          };
        } else state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
          action: 'end',
          conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
          setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
        };
      }
    },
    // изменение типа действия конечной точки
    changeActionTypeEndpoint: (state, action: PayloadAction<{ channel: string, idx: number, action: Action, actionType: ActionType, text?: string[] }>) => {
      if (state.endpointElem.endpoint) {
        if (action.payload.action === 'say') {

          switch (action.payload.actionType) {
            case 'linear':
            case 'random':
              state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                text: action.payload.text || [''],
                conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
                setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
              };
              break;
            case 'external':
              state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                script: '',
                conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
                setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
              };
              break;
            case 'internal':
              state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
                action: action.payload.action,
                type: action.payload.actionType,
                service: 'qas',
                categories: (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] as IActionInternal).categories || {},
                useCache: 'checked',
                useGPT: 'no',
                creative: 'no',
                addAlias: 'no',
                conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
                setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
              };
              break;
            default:
              break;
          }
        } else state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
          action: action.payload.action,
          conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
          setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
        };
      }
    },
    changeSetAnswered: (state, action: PayloadAction<{ channel: string, idx: number, flag: boolean }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered = action.payload.flag;
      }
    },
    // добавление фразы в действие конечной точки (по факту - перезапись с добавлением)
    addPhraseInActionEndpoint: (state, action: PayloadAction<{ channel: string, idx: number, actionObj: IActionPhrase }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = action.payload.actionObj;
      }
    },
    // изменение фразы в действии конечной точки
    changePhraseInActionEndpoint: (state, action: PayloadAction<{ channel: string, idxAction: number, idxPhrase: number, text: string }>) => {
      if (state.endpointElem.endpoint) {
        (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idxAction] as IActionPhrase).text[action.payload.idxPhrase] = action.payload.text;
      }
    },
    // удаление фразы в действии конечной точки
    deletePhraseInActionEndpoint: (state, action: PayloadAction<{ channel: string, idxAction: number, idxPhrase: number }>) => {
      if (state.endpointElem.endpoint) {
        (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idxAction] as IActionPhrase).text.splice(action.payload.idxPhrase, 1);
      }
    },
    // изменение скрипта конечной точки
    changeScriptEndpoint: (state, action: PayloadAction<{ channel: string, idx: number, script: string }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
          action: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'external',
          script: action.payload.script,
          conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
          setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
        };
      }
    },
    // изменение данных сервиса действия конечной точки
    changeServiceEndpoint: (state, action: PayloadAction<{ channel: string, idx: number, service: ServiceType, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] = {
          action: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].action as Extract<Action, 'say'>,
          type: 'internal',
          service: action.payload.service,
          categories: (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx] as IActionInternal).categories,
          useCache: action.payload.useCache,
          useGPT: action.payload.useGPT,
          creative: action.payload.creative,
          addAlias: action.payload.addAlias,
          conditions: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].conditions,
          setAnswered: state.endpointElem.endpoint.actions[action.payload.channel][action.payload.idx].setAnswered,
        };
      }
    },
    // добавление данных категории конечной точки
    addCategoryDataEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.endpointElem.endpoint) {
        const actionInternal = state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] as IActionInternal;
        if ('categories' in actionInternal) {
          if (action.payload.categoryId in actionInternal.categories) {
            if (actionInternal.categories[action.payload.categoryId].length > action.payload.categoryBlockIdx) {
              actionInternal.categories[action.payload.categoryId][action.payload.categoryBlockIdx].push(action.payload.data);
            } else {
              actionInternal.categories[action.payload.categoryId].push([action.payload.data]);
            }
          } else {
            actionInternal.categories[action.payload.categoryId] = [[action.payload.data]];
          }
        } else {
          (actionInternal as IActionInternal).categories = {
            [action.payload.categoryId]: [[action.payload.data]]
          };
        }
      }
    },
    // изменение типа данных категории конечной точки
    changeTypeCategoryEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.endpointElem.endpoint) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId][action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение данных категории конечной точки
    changeCategoryDataEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number, data: IConditionData }>) => {
      if (state.endpointElem.endpoint) {
        (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId][action.payload.categoryBlockIdx][action.payload.categoryIdx] = action.payload.data;
      }
    },
    // удаление данных категории конечной точки
    deleteCategoryDataEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, categoryId: string, categoryBlockIdx: number, categoryIdx: number }>) => {
      if (state.endpointElem.endpoint) {
        const actionInternalCategoryId = (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId];
        if (actionInternalCategoryId[action.payload.categoryBlockIdx].length > 1) {
          actionInternalCategoryId[action.payload.categoryBlockIdx].splice(action.payload.categoryIdx, 1);
        } else {
          if (actionInternalCategoryId.length > 1) {
            actionInternalCategoryId.splice(action.payload.categoryBlockIdx, 1);
          } else {
            delete (state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] as IActionInternal).categories[action.payload.categoryId];
          }
        }
      }
    },
    // -------------------------------------------------------------------------
    // добавление условия запуска действия конечной точки
    addConditionRunActionEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, conditionBlockIdx: number, data: ConditionVariantType }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx].conditions; // условия запуска действия
        if (Array.isArray(conditions)) {
          if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
          else conditions.push([action.payload.data]);
        } else {
          state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx] = {
            ...state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx],
            conditions: [[action.payload.data]],
          };
        }
      }
    },
    // изменение типа условия запуска действия конечной точки
    changeTypeConditionRunActionEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, conditionType: ConditionType }>) => {
      if (state.endpointElem.endpoint) {
        const creatingObjectWithCondition = (type: ConditionType): ConditionVariantType => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'interval':
              return {
                type,
                id: '',
              };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx].conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия запуска действия конечной точки
    changeValuesConditionRunActionEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number, data: ConditionVariantType }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx].conditions?.[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия запуска действия конечной точки
    deleteConditionRunActionEndpoint: (state, action: PayloadAction<{ channel: string, channelIdx: number, conditionBlockIdx: number, conditionIdx: number }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.actions[action.payload.channel][action.payload.channelIdx].conditions; // условия
        if (conditions && conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions?.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // --------------------------------------------------------------
    // добавление условия входа в КТ
    addConditionEntry: (state, action: PayloadAction<{ conditionBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.entry; // условия входа
        if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
        else conditions.push([action.payload.data]);
      }
    },
    // изменение типа данных условия входа КТ
    changeTypeConditionEntryEndpoint: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.endpointElem.endpoint) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        state.endpointElem.endpoint.entry[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия входа в КТ
    editConditionEntry: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.entry[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия входа в КТ
    deleteConditionEntry: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.entry; // условия
        if (conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // --------------------------------------------------------------
    // добавление БЛОКА условий запуска КТ
    addConditionRunBlock: (state, action: PayloadAction<(IConditionVariable | IConditionData)[]>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.run.push([...action.payload]);
      }
    },
    // добавление условия запуска КТ
    addConditionRun: (state, action: PayloadAction<{ conditionBlockIdx: number, data: IConditionData | IConditionVariable }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.run; // условия запуска
        if (conditions[action.payload.conditionBlockIdx]) conditions[action.payload.conditionBlockIdx].push(action.payload.data);
        else conditions.push([action.payload.data]);
      }
    },
    // изменение типа данных условия запуска КТ
    changeTypeConditionRunEndpoint: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number, conditionType: Extract<ConditionType, 'data' | 'variable'> }>) => {
      if (state.endpointElem.endpoint) {
        const creatingObjectWithCondition = (type: Extract<ConditionType, 'data' | 'variable'>): IConditionData | IConditionVariable => {
          switch (type) {
            case 'data':
              return {
                type,
                depth: 5,
                id: '',
                operation: 'exists',
                value: '',
              };
            case 'variable':
              return {
                type,
                id: '',
                operation: 'exists',
                value: '',
              };
            // case 'interval':
            //   return {
            //     type,
            //     id: '',
            //   };
            default:
              const defaultType: never = type;
              return defaultType;
          };
        };
        state.endpointElem.endpoint.run[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, creatingObjectWithCondition(action.payload.conditionType));
      }
    },
    // изменение значений условия запуска КТ
    editConditionRun: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number, data: IConditionData }>) => {
      if (state.endpointElem.endpoint) {
        state.endpointElem.endpoint.run[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1, action.payload.data);
      }
    },
    // удаление условия запуска КТ
    deleteConditionRun: (state, action: PayloadAction<{ conditionBlockIdx: number, conditionIdx: number }>) => {
      if (state.endpointElem.endpoint) {
        const conditions = state.endpointElem.endpoint.run; // условия
        if (conditions[action.payload.conditionBlockIdx].length > 1) conditions[action.payload.conditionBlockIdx].splice(action.payload.conditionIdx, 1);
        else conditions.splice(action.payload.conditionBlockIdx, 1);
      }
    },
    // -------------------------------------------------------------------------
    // добавление реплики в диалог
    addReplicaInDialog: (state, action: PayloadAction<IDialog>) => {
      state.debuggerAnswer.dialog.push({ who: action.payload.who, message: action.payload.message, audioUrl: action.payload.audioUrl });
    },
    // дополнение реплики распознанным текстом
    additionLastReplicaWithText: (state, action: PayloadAction<string>) => {
      state.debuggerAnswer.dialog[state.debuggerAnswer.dialog.length - 1].message = action.payload;
    },
    // -------------------------------------------------------------------------
    // очистка state
    clearState: (state) => {
      state.dataList = initialState.dataList;
      state.dataElem = initialState.dataElem;
      state.addingData = initialState.addingData;
      state.editingData = initialState.editingData;
      state.deletingData = initialState.deletingData;

      state.endpointList = initialState.endpointList;
      state.endpointElem = initialState.endpointElem;
      state.addingEndpoint = initialState.addingEndpoint;
      state.editingEndpoint = initialState.editingEndpoint;
      state.deletingEndpoint = initialState.deletingEndpoint;

      state.debuggerAnswer = initialState.debuggerAnswer;
      state.debuggerSession = initialState.debuggerSession;
    },
    // очистка списка элементов данных
    clearDataList: (state) => {
      state.dataList = initialState.dataList;
    },
    // очистка элемента данных
    clearDataElem: (state) => {
      state.dataElem = initialState.dataElem;
    },
    // очистка статуса добавления элемента данных
    clearAddingData: (state) => {
      state.addingData = initialState.addingData;
    },
    // очистка статуса изменения элемента данных
    clearEditingData: (state) => {
      state.editingData = initialState.editingData;
    },
    // очистка статуса удаления элемента данных
    clearDeletingData: (state) => {
      state.deletingData = initialState.deletingData;
    },
    // очистка списка конечных точек
    clearEndpointList: (state) => {
      state.endpointList = initialState.endpointList;
    },
    // очистка конечной точки
    clearEndpointElem: (state) => {
      state.endpointElem = initialState.endpointElem;
    },
    // очистка статуса добавления конечной точки
    clearAddingEndpoint: (state) => {
      state.addingEndpoint = initialState.addingEndpoint;
    },
    // очистка статуса изменения конечной точки
    clearEditingEndpoint: (state) => {
      state.editingEndpoint = initialState.editingEndpoint;
    },
    // очистка статуса удаления конечной точки
    clearDeletingEndpoint: (state) => {
      state.deletingEndpoint = initialState.deletingEndpoint;
    },
    // очистка ответа робота в отладчика
    clearDebuggerAnswer: (state) => {
      state.debuggerAnswer = initialState.debuggerAnswer;
    },
    // очистка данных сессии отладчика
    clearDebuggerSession: (state) => {
      state.debuggerSession = initialState.debuggerSession;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDataList.pending, (state) => {
        state.dataList.status = RequestStatus.LOADING;
      })
      .addCase(getDataList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.dataList.status = RequestStatus.IDLE;
          state.dataList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
          const dictionary: Record<string, string> = {}; // создаем словарь id-name
          action.payload.forEach(dataElem => dictionary[dataElem.id] = dataElem.name);
          state.dataList.dictionary = dictionary;
        } else state.dataList.status = RequestStatus.FAILED;
      })
      .addCase(getDataList.rejected, (state, action: PayloadAction<unknown>) => {
        state.dataList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.dataList.error = action.payload.response?.data.error;
          state.dataList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getDataElem.pending, (state) => {
        state.dataElem.status = RequestStatus.LOADING;
      })
      .addCase(getDataElem.fulfilled, (state, action) => {
        if (action.payload && 'data' in action.payload) {
          state.dataElem.status = RequestStatus.IDLE;
          state.dataElem.element = action.payload.data;
        } else state.dataElem.status = RequestStatus.FAILED;
      })
      .addCase(getDataElem.rejected, (state, action: PayloadAction<unknown>) => {
        state.dataElem.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.dataElem.error = action.payload.response?.data.error;
          state.dataElem.message = action.payload.response?.data.message;
        }
      })
      .addCase(addDataElem.pending, (state) => {
        state.addingData.status = RequestStatus.LOADING;
      })
      .addCase(addDataElem.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingData.status = RequestStatus.IDLE;
          state.addingData.error = action.payload.error;
          state.addingData.message = action.payload.message;
          // пишем id в store новосозданного элемента данных
          if (state.dataElem.element && action.payload.id) {
            state.dataElem.element.id = action.payload.id;
          }
        } else state.addingData.status = RequestStatus.FAILED;
      })
      .addCase(addDataElem.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingData.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingData.error = action.payload.response?.data.error;
          state.addingData.message = action.payload.response?.data.message;
        }
      })
      .addCase(editDataElem.pending, (state) => {
        state.editingData.status = RequestStatus.LOADING;
      })
      .addCase(editDataElem.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingData.status = RequestStatus.IDLE;
          state.editingData.error = action.payload.error;
          state.editingData.message = action.payload.message;
        } else state.editingData.status = RequestStatus.FAILED;
      })
      .addCase(editDataElem.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingData.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingData.error = action.payload.response?.data.error;
          state.editingData.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteDataElem.pending, (state) => {
        state.deletingData.status = RequestStatus.LOADING;
      })
      .addCase(deleteDataElem.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingData.status = RequestStatus.IDLE;
          state.deletingData.error = action.payload.error;
          state.deletingData.message = action.payload.message;
        } else state.deletingData.status = RequestStatus.FAILED;
      })
      .addCase(deleteDataElem.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingData.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingData.error = action.payload.response?.data.error;
          state.deletingData.message = action.payload.response?.data.message;
        }
      })

      .addCase(getEndpointList.pending, (state) => {
        state.endpointList.status = RequestStatus.LOADING;
      })
      .addCase(getEndpointList.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.endpointList.status = RequestStatus.IDLE;
          state.endpointList.data = action.payload.sort((a, b) => {
            if (a.name > b.name) return 1;
            else if (a.name < b.name) return -1;
            else return 0;
          });
          const dictionary: Record<string, string> = {}; // создаем словарь id-name
          action.payload.forEach(endpointElem => dictionary[endpointElem.id] = endpointElem.name);
          state.endpointList.dictionary = dictionary;
        } else state.endpointList.status = RequestStatus.FAILED;
      })
      .addCase(getEndpointList.rejected, (state, action: PayloadAction<unknown>) => {
        state.endpointList.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.endpointList.error = action.payload.response?.data.error;
          state.endpointList.message = action.payload.response?.data.message;
        }
      })
      .addCase(getEndpoint.pending, (state) => {
        state.endpointElem.status = RequestStatus.LOADING;
      })
      .addCase(getEndpoint.fulfilled, (state, action) => {
        if (action.payload && 'data' in action.payload) {
          state.endpointElem.status = RequestStatus.IDLE;
          state.endpointElem.endpoint = action.payload.data;
        } else state.endpointElem.status = RequestStatus.FAILED;
      })
      .addCase(getEndpoint.rejected, (state, action: PayloadAction<unknown>) => {
        state.endpointElem.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.endpointElem.error = action.payload.response?.data.error;
          state.endpointElem.message = action.payload.response?.data.message;
        }
      })
      .addCase(addEndpoint.pending, (state) => {
        state.addingEndpoint.status = RequestStatus.LOADING;
      })
      .addCase(addEndpoint.fulfilled, (state, action) => {
        if (action.payload) {
          state.addingEndpoint.status = RequestStatus.IDLE;
          state.addingEndpoint.error = action.payload.error;
          state.addingEndpoint.message = action.payload.message;
          // пишем id в store новосозданной конечной точки
          if (state.endpointElem.endpoint && action.payload.id) {
            state.endpointElem.endpoint.id = action.payload.id;
          }
        } else state.addingEndpoint.status = RequestStatus.FAILED;
      })
      .addCase(addEndpoint.rejected, (state, action: PayloadAction<unknown>) => {
        state.addingEndpoint.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.addingEndpoint.error = action.payload.response?.data.error;
          state.addingEndpoint.message = action.payload.response?.data.message;
        }
      })
      .addCase(editEndpoint.pending, (state) => {
        state.editingEndpoint.status = RequestStatus.LOADING;
      })
      .addCase(editEndpoint.fulfilled, (state, action) => {
        if (action.payload) {
          state.editingEndpoint.status = RequestStatus.IDLE;
          state.editingEndpoint.error = action.payload.error;
          state.editingEndpoint.message = action.payload.message;
        } else state.editingEndpoint.status = RequestStatus.FAILED;
      })
      .addCase(editEndpoint.rejected, (state, action: PayloadAction<unknown>) => {
        state.editingEndpoint.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.editingEndpoint.error = action.payload.response?.data.error;
          state.editingEndpoint.message = action.payload.response?.data.message;
        }
      })
      .addCase(deleteEndpoint.pending, (state) => {
        state.deletingEndpoint.status = RequestStatus.LOADING;
      })
      .addCase(deleteEndpoint.fulfilled, (state, action) => {
        if (action.payload) {
          state.deletingEndpoint.status = RequestStatus.IDLE;
          state.deletingEndpoint.error = action.payload.error;
          state.deletingEndpoint.message = action.payload.message;
        } else state.deletingEndpoint.status = RequestStatus.FAILED;
      })
      .addCase(deleteEndpoint.rejected, (state, action: PayloadAction<unknown>) => {
        state.deletingEndpoint.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.deletingEndpoint.error = action.payload.response?.data.error;
          state.deletingEndpoint.message = action.payload.response?.data.message;
        }
      })
      .addCase(getDebuggerAnswer.pending, (state) => {
        state.debuggerAnswer.status = RequestStatus.LOADING;
      })
      .addCase(getDebuggerAnswer.fulfilled, (state, action) => {
        if (typeof action.payload === 'object' && 'answer' in action.payload) {
          state.debuggerAnswer.status = RequestStatus.IDLE;
          state.debuggerAnswer.answer = action.payload.answer;
          if (action.payload.session) state.debuggerAnswer.session = action.payload.session;
          else state.debuggerAnswer.endOfSession = true;
          if (action.payload.question) state.debuggerAnswer.question = action.payload.question;
          else state.debuggerAnswer.question = '';
        } else {
          state.debuggerAnswer.status = RequestStatus.FAILED;
          if (typeof action.payload === 'object' && 'error' in action.payload) {
            state.debuggerAnswer.error = action.payload.error;
            state.debuggerAnswer.message = action.payload.message;
          }
        }
      })
      .addCase(getDebuggerAnswer.rejected, (state, action: PayloadAction<unknown>) => {
        state.debuggerAnswer.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.debuggerAnswer.error = action.payload.response?.data.error;
          state.debuggerAnswer.message = action.payload.response?.data.message;
        }
      })
      .addCase(getDebuggerSession.pending, (state) => {
        state.debuggerSession.status = RequestStatus.LOADING;
      })
      .addCase(getDebuggerSession.fulfilled, (state, action) => {
        if (typeof action.payload?.responseData === 'object' && 'started' in action.payload.responseData) {
          state.debuggerSession.status = RequestStatus.IDLE;
          // подмена id на name у элементов данных/конечных точек/категорий
          state.debuggerSession.sessionData = {
            ...action.payload.responseData,
            current: {
              ...action.payload.responseData.current,
              id: action.payload.responseData.current.id ? state.endpointList.dictionary[action.payload.responseData.current.id] : action.payload.responseData.current.id,
              nearest: action.payload.responseData.current.nearest ? state.endpointList.dictionary[action.payload.responseData.current.nearest] : action.payload.responseData.current.nearest,
              entry: Array.isArray(action.payload.responseData.current.entry) ?
                action.payload.responseData.current.entry.map(dataElemId => state.dataList.dictionary[dataElemId] || dataElemId)
                :
                action.payload.responseData.current.entry,
              missingRunData: Array.isArray(action.payload.responseData.current.missingRunData) ?
                action.payload.responseData.current.missingRunData.map(dataElemId => state.dataList.dictionary[dataElemId] || dataElemId)
                :
                action.payload.responseData.current.missingRunData,
              category: Array.isArray(action.payload.responseData.current.category) ?
                action.payload.responseData.current.category.map(categoryId => action.payload?.dictionaryCategories[categoryId] || categoryId)
                :
                action.payload.responseData.current.category,
            },
          };
        } else state.debuggerSession.status = RequestStatus.FAILED;
      })
      .addCase(getDebuggerSession.rejected, (state, action: PayloadAction<unknown>) => {
        state.debuggerSession.status = RequestStatus.FAILED;
        if (action.payload instanceof AxiosError) {
          state.debuggerSession.error = action.payload.response?.data.error;
          state.debuggerSession.message = action.payload.response?.data.message;
        }
      });
  },
});

export const { addingAddElementFormData, addSlaveData, editSlaveData, deleteSlaveData, changeForEndpointRunOnly, addingDataElemAction, editDataElemName, editDataElemRType, changeActionDataElem, deleteActionDataElem, changeActionTypeDataElem, addPhraseInActionDataElem, changePhraseInActionDataElem, deletePhraseInActionDataElem, changeScriptDataElem, changeServiceDataElem, addCategoryDataElem, changeTypeCategoryDataElem, changeCategoryDataElem, deleteCategoryDataElem, addConditionRunActionDataElem, changeTypeConditionRunActionDataElem, changeValuesConditionRunActionDataElem, deleteConditionRunActionDataElem, editEndpointName, editEndpointType, editEndpointEntry, addingAddEndpointFormData, addingEndpointAction, deleteActionEndpoint, changeActionEndpoint, changeActionTypeEndpoint, changeSetAnswered, addPhraseInActionEndpoint, changePhraseInActionEndpoint, deletePhraseInActionEndpoint, changeScriptEndpoint, changeServiceEndpoint, addCategoryDataEndpoint, changeTypeCategoryEndpoint, changeCategoryDataEndpoint, deleteCategoryDataEndpoint, addConditionRunActionEndpoint, changeTypeConditionRunActionEndpoint, changeValuesConditionRunActionEndpoint, deleteConditionRunActionEndpoint, addConditionEntry, changeTypeConditionEntryEndpoint, editConditionEntry, deleteConditionEntry, addConditionRunBlock, addConditionRun, changeTypeConditionRunEndpoint, editConditionRun, deleteConditionRun, addReplicaInDialog, additionLastReplicaWithText, clearState, clearDataList, clearDataElem, clearAddingData, clearEditingData, clearDeletingData, clearEndpointList, clearEndpointElem, clearAddingEndpoint, clearEditingEndpoint, clearDeletingEndpoint, clearDebuggerAnswer, clearDebuggerSession } = sesSlice.actions;

export const selectDataList = (state: RootState) => state.ses.dataList;
export const selectDataElem = (state: RootState) => state.ses.dataElem;
export const selectAddingData = (state: RootState) => state.ses.addingData;
export const selectEditingData = (state: RootState) => state.ses.editingData;
export const selectDeletingData = (state: RootState) => state.ses.deletingData;

export const selectEndpointList = (state: RootState) => state.ses.endpointList;
export const selectEndpointElem = (state: RootState) => state.ses.endpointElem;
export const selectAddingEndpoint = (state: RootState) => state.ses.addingEndpoint;
export const selectEditingEndpoint = (state: RootState) => state.ses.editingEndpoint;
export const selectDeletingEndpoint = (state: RootState) => state.ses.deletingEndpoint;

export const selectDebuggerAnswer = (state: RootState) => state.ses.debuggerAnswer;
export const selectDebuggerSession = (state: RootState) => state.ses.debuggerSession;

export default sesSlice.reducer;
